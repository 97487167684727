import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {persistor, store} from './redux/store';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import {Auth0Provider} from '@auth0/auth0-react';
import {FpjsProvider} from '@fingerprintjs/fingerprintjs-pro-react';
import {CLIENT_ID, DOMAIN, FPJS_PUBLIC_API_KEY} from './utils/env';
import {
  defaultEndpoint as fpDefaultEndpoint,
  defaultScriptUrlPattern as fpDefaultScriptUrlPattern,
} from '@fingerprintjs/fingerprintjs-pro'

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <FpjsProvider
      cacheLocation="memory"
      loadOptions={{
        apiKey: FPJS_PUBLIC_API_KEY,
        scriptUrlPattern: [
          `https://fp.visoplayer.com/web/v<version>/<apiKey>/loader_v<loaderVersion>.js`,
          fpDefaultScriptUrlPattern,
        ],
        endpoint: [
          `https://fp.visoplayer.com`,
          fpDefaultEndpoint,
        ],
      }}
    >
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Auth0Provider
            domain={DOMAIN}
            clientId={CLIENT_ID}
            redirectUri={window.location.origin}
          >
            <App />
          </Auth0Provider>
          ,
        </PersistGate>
      </Provider>
    </FpjsProvider>
  </React.StrictMode>
);

import {useState} from 'react';
import styled, {ThemeProvider} from 'styled-components';
import {darkTheme, lightTheme} from './utils/Theme';
import Menu from './components/Menu';
import Navbar from './components/Navbar';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Home from './pages/Home';
import Video from './pages/Video';
import SignIn from './pages/SignIn';
import Profile from './pages/Profile';
import {useDispatch} from 'react-redux';
import Search from './pages/Search';
import {PURGE} from 'redux-persist';
import {PERSIST_KEY} from './redux/store';

const Container = styled.div`
  display: flex;
`;
const Main = styled.div`
  flex: 7;
  background-color: ${({ theme }) => theme.bg};
`;
const Wrapper = styled.div`
  padding: 22px 96px;
`;

const App = () => {
  const [darkMode, setDarkMode] = useState(true);
  const dispatch = useDispatch();

  const handleResetDemo = () => {
    dispatch({
      type: PURGE,
      key: PERSIST_KEY,
      result: () => null,
    });
  };

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <Container>
        <BrowserRouter>
          <Menu
            darkMode={darkMode}
            setDarkMode={setDarkMode}
            onDemoReset={handleResetDemo}
          />
          <Main>
            <Navbar />
            <Wrapper>
              <Routes>
                <Route path="/">
                  <Route index element={<Home type="random" />} />
                  <Route path="trend" element={<Home type="trend" />} />
                  <Route path="subscriptions" element={<Home type="sub" />} />
                  <Route path="search" element={<Search />} />
                  <Route path="signin" element={<SignIn />} />
                  <Route path="video">
                    <Route path=":id" element={<Video />} />
                  </Route>
                </Route>

                <Route
                  path="profile"
                  element={
                    <Profile darkMode={darkMode} setDarkMode={setDarkMode} />
                  }
                />
              </Routes>
            </Wrapper>
          </Main>
        </BrowserRouter>
      </Container>
    </ThemeProvider>
  );
};

export default App;

import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import Card from '../components/Card';
import axios from 'axios';
import {useDispatch, useSelector} from 'react-redux';
import {fingerprint, tags} from '../redux/userSlice.js';
import {useVisitorData} from '@fingerprintjs/fingerprintjs-pro-react';
import {API_URL} from '../utils/env';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Home = ({ type }) => {
  const [videos, setVideos] = useState([]);
  const { getData } = useVisitorData();

  const id = useSelector((state) => state.user.fingerprint);
  const t = useSelector((state) => state.user.tags);

  const [visitorId, setVisitorId] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    getData()
      .then((result) => {
        setVisitorId(result.visitorId);
        if (result.visitorId || !id) {
          console.log("fingerprint dispatched");
          runtime();
          return dispatch(fingerprint(result.visitorId));
        }
      });
  }, [visitorId, dispatch, id]);

  useEffect(() => {
    if (type === "trend") {
      console.log("trending video function running");
      fetchTrendingVideos();
    } else if (type !== "trend") {
      fetchVideos();
    }
  }, [type]);

  const fetchTrendingVideos = async () => {
    // const fetchVideos = async () => {
    if (t === undefined || t.length < 1) {
      const res = await axios.get(`${API_URL}/records/${visitorId}`);
      console.log(
        `no tag, retrieving fingerprint history. ${res.data.tags}. ${visitorId}`
      );
      const resp = await axios.get(`${API_URL}/videos/tags?tags=${res.data.tags}`);
      setVideos(resp.data);
      dispatch(tags(res.data.tags));
    } else {
      console.log("pulled from cache");
      const cache = await axios.get(`${API_URL}/videos/tags?tags=${t}`);
      setVideos(cache.data);
    }
    //   console.log(`no tag, retrieving fingerprint history. ${res.data.tags}. `);
    //   const resp = await axios.get(`${API_URL}/videos/tags?tags=${res.data.tags}`);
    //   dispatch(tags(res.data.tags));
    //   setVideos(resp.data);
    // } else if (t.length >= 1) {
    // console.log("not working");
  };
  const fetchVideos = async () => {
    const res = await axios.get(`${API_URL}/videos/${type}`);
    setVideos(res.data);
  };

  // useEffect(() => {
  const getRecord = async () => {
    if (visitorId) {
      const res = await axios.get(`${API_URL}/records/${visitorId}`);
      // const [first, second] = await Promise.all([
      //commented out lines for if you want to show linking when a user signs up
      // axios.get(`${API_URL}/users/records/${visitorId}`),

      if (res.data !== null) {
        // if (first || second) {
        // return console.log("record found, skipping creation");
        // return setRecord(res.data._id);
        return console.log("skip duplicate api call");
      } else {
        return axios
          .post(`${process.env.REACT_APP_API_URL}/records/`, {
            _id: visitorId,
            visitorId: visitorId,
          })
          .then((res) => {
            console.log("new record created");
            return dispatch(fingerprint(visitorId));
          });
      }
    }
  };

  // });
  const runtime = () => {
    if (visitorId || id) {
      return getRecord();
    }
  };

  // const tagHandler = async (video) => {
  //   // dispatch(loginStart());
  //   try {
  //     console.log(video.currentVideo._id);
  //     // const res = await axios
  //     //   .post("/auth/signin", { email, password })
  //     //   .then((res) => {
  //     //     if (res.data.visitorId.indexOf(data.visitorId) === -1) {
  //     //       const ids = res.data.visitorId;
  //     //       const count = ids.unshift(data.visitorId);
  //     //       return axios.put(`${API_URL}/users/${res.data._id}`, {
  //     //         visitorId: ids,
  //   } catch (err) {
  //     console.log(err);
  //     return err;
  //   }
  // };
  // } else {
  // dispatch(loginSuccess(res.data));
  // navigate("/");

  return (
    <Container>
      {videos.map((video) => (
        <Card key={video._id} video={video} />
      ))}
    </Container>
  );
};

export default Home;

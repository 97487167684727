import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentUser: null,
  loading: false,
  error: false,
  tags: [],
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginStart: (state) => {
      state.loading = true;
    },
    loginSuccess: (state, action) => {
      state.loading = false;
      state.currentUser = action.payload;
    },
    fingerprint: (state, action) => {
      state.fingerprint = action.payload;
    },
    loginFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    loggedOut: (state) => {
      state.currentUser = null;
      state.loading = false;
      state.error = false;
    },
    tags: (state, action) => {
      state.tags = action.payload;
    },
    subscription: (state, action) => {
      if (state.currentUser.subscribedUsers.includes(action.payload)) {
        state.currentUser.subscribedUsers.splice(
          state.currentUser.subscribedUsers.findIndex(
            (channelId) => channelId === action.payload
          ),
          1
        );
      } else {
        state.currentUser.subscribedUsers.push(action.payload);
      }
    },
  },
});

export const {
  loginStart,
  loginSuccess,
  loginFailure,
  loggedOut,
  subscription,
  fingerprint,
  tags,
} = userSlice.actions;

export default userSlice.reducer;

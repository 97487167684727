import React from 'react';
import {useAuth0} from '@auth0/auth0-react';
import styled from 'styled-components';

const Container = styled.div`
  flex: 1;
  background-color: ${({ theme }) => theme.bg};
  height: 200vh;

  color: ${({ theme }) => theme.text};
  font-size: 16px;
  position: sticky;
  top: 0;
`;

const Wrapper = styled.div`
  padding: 18px 26px;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  padding: 7.5px 0px;
  &:hover {
    background-color: ${({ theme }) => theme.soft};
  }
`;

const Profile = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    isAuthenticated && (
      <Container>
        <Item>
          <h2>Profile</h2>
        </Item>
        <Wrapper>
          <img src={user.picture} alt={user.name} />
        </Wrapper>
        <Item> {user.name}</Item>
        <Item>Email: {user.email}</Item>

        <Item>Known devices: </Item>
        <Item>
          {user["https://visoplayer.com/device_ids"].map((d) => (
            <li key={d}>{d}</li>
          ))}
        </Item>
        <Item>
          Consecutive logins from device:{" "}
          {user["https://visoplayer.com/consecutive_logins"]}
        </Item>
      </Container>
    )
  );
};

export default Profile;

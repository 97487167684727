import axios from 'axios';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import styled from 'styled-components';
import {loginSuccess} from '../redux/userSlice.js';
import {useVisitorData} from '@fingerprintjs/fingerprintjs-pro-react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import {useNavigate} from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 56px);
  color: ${({ theme }) => theme.text};
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${({ theme }) => theme.bgLighter};
  border: 3px solid ${({ theme }) => theme.soft};
  padding: 20px 50px;
  gap: 20px;
  height: 50vh;
  margin-top: 10px;
`;

const Title = styled.h1`
  font-size: 24px;
`;

const SubTitle = styled.h2`
  font-size: 20px;
  font-weight: 300;
`;

const Input = styled.input`
  border: 1px solid ${({ theme }) => theme.soft};
  border-radius: 3px;
  padding: 10px;
  background-color: transparent;
  width: 100%;
  color: ${({ theme }) => theme.text};
`;

const Button = styled.button`
  border-radius: 3px;
  border: none;
  padding: 10px 20px;
  font-weight: 500;
  cursor: pointer;
  background-color: ${({ theme }) => theme.soft};
  color: ${({ theme }) => theme.textSoft};
`;

const More = styled.div`
  display: flex;
  margin-top: 10px;
  font-size: 12px;
  color: ${({ theme }) => theme.textSoft};
`;

const Links = styled.div`
  margin-left: 50px;
`;

const Link = styled.span`
  margin-left: 30px;
`;

const SignIn = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data } = useVisitorData();

  // const handleLogin = async (e) => {
  //   e.preventDefault();
  //   dispatch(loginStart());
  //   try {
  //     const res = await axios
  //       .post("/auth/signin", { email, password })
  //       .then((res) => {
  //         dispatch(loginSuccess(res.data));
  //         navigate("/");
  //         if (res.data.visitorId.indexOf(data.visitorId) === -1) {
  //           const ids = res.data.visitorId;
  //           const count = ids.unshift(data.visitorId);
  //           axios.put(`${process.env.REACT_APP_API_URL}/users/${res.data._id}`, {
  //             visitorId: ids,
  //           });
  //           console.log("ids:", ids, "count: ", count);
  //         }
  //       });
  //   } catch (err) {
  //     dispatch(loginFailure());
  //   }
  // };

  const handleSignup = async (e) => {
    e.preventDefault();
    const deviceExists = await axios.get(`${process.env.REACT_APP_API_URL}/users/search/${data.visitorId}`);

    if (deviceExists.data >= 2) {
      return setErrorMessage(
        `You have reached the maximum # of accounts allowed for this Device. If you believe that this is a mistake, please contact us at 1800`
      );
    } else {
      const res = await axios.post(
        `${process.env.REACT_APP_DOMAIN}/dbconnections/signup`,
        {
          client_id: process.env.REACT_APP_CLIENT_ID,
          email: email,
          password: password,
          connection: "fpdb",
          name: name,
          username: name,
          user_metadata: { visitorId: data.visitorId },
        }
      );
      dispatch(loginSuccess(res.data));
      navigate("/");
    }
  };

  return (
    <Container>
      {errorMessage ? (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {errorMessage}
        </Alert>
      ) : (
        <div></div>
      )}

      <Wrapper>
        {/* <Title>Sign in</Title>
        <SubTitle>to continue to MooTube</SubTitle>
        <Input
          placeholder="email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <Input
          type="password"
          placeholder="password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button onClick={handleLogin}>Sign in</Button>

        <Title>or</Title> */}
        <Title>Sign up</Title>
        <SubTitle>to continue to Viso</SubTitle>
        <Input
          placeholder="username"
          onChange={(e) => setName(e.target.value)}
        />
        <Input placeholder="email" onChange={(e) => setEmail(e.target.value)} />
        <Input
          type="password"
          placeholder="password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button onClick={handleSignup}>Sign up</Button>
      </Wrapper>
      <More>
        English(USA)
        <Links>
          <Link>Help</Link>
          <Link>Privacy</Link>
          <Link>Terms</Link>
        </Links>
      </More>
    </Container>
  );
};

export default SignIn;

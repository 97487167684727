import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbDownOffAltOutlinedIcon from '@mui/icons-material/ThumbDownOffAltOutlined';
import ReplyOutlinedIcon from '@mui/icons-material/ReplyOutlined';
import AddTaskOutlinedIcon from '@mui/icons-material/AddTaskOutlined';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import axios from 'axios';
import {dislike, fetchSuccess, like} from '../redux/videoSlice';
import {subscription, tags} from '../redux/userSlice';
import Recommendation from '../components/Recommendation';
import {useVisitorData} from '@fingerprintjs/fingerprintjs-pro-react';
import {numberFormatter} from '../utils/format';

const Container = styled.div`
  display: flex;
  gap: 24px;
`;

const Content = styled.div`
  flex: 5;
`;
const VideoWrapper = styled.div``;

const Title = styled.h1`
  font-size: 18px;
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.text};
`;

const Details = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Info = styled.span`
  color: ${({ theme }) => theme.textSoft};
`;

const Buttons = styled.div`
  display: flex;
  gap: 20px;
  color: ${({ theme }) => theme.text};
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
`;

const Hr = styled.hr`
  margin: 15px 0px;
  border: 0.5px solid ${({ theme }) => theme.soft};
`;

const Channel = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ChannelInfo = styled.div`
  display: flex;
  gap: 20px;
`;

const Image = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;

const ChannelDetail = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.text};
`;

const ChannelName = styled.span`
  font-weight: 500;
`;

const ChannelCounter = styled.span`
  margin-top: 5px;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.textSoft};
  font-size: 12px;
`;

const Description = styled.p`
  font-size: 14px;
`;

const Subscribe = styled.button`
  background-color: #cc1a00;
  font-weight: 500;
  color: white;
  border: none;
  border-radius: 3px;
  height: max-content;
  padding: 10px 20px;
  cursor: pointer;
`;

// const VideoFrame = styled.video`
//   max-height: 720px;
//   width: 100%;
//   object-fit: cover;
// `;

const Video = (props) => {
  const { currentUser } = useSelector((state) => state.user);
  const { currentVideo } = useSelector((state) => state.video);
  const id = useSelector((state) => state.user.fingerprint);
  const t = useSelector((state) => state.user.tags);
  const { data } = useVisitorData();

  const dispatch = useDispatch();

  const path = useLocation().pathname.split("/")[2];

  const [video, setVideo] = useState({});

  const [channel, setChannel] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const videoRes = await axios.get(`${process.env.REACT_APP_API_URL}/videos/find/${path}`);
        const channelRes = await axios.get(
          `${process.env.REACT_APP_API_URL}/users/find/${videoRes.data.userId}`
        );
        setVideo(videoRes.data);
        setChannel(channelRes.data);
        setChannel(channelRes.data);
        dispatch(fetchSuccess(videoRes.data));

        if (id || data.visitorId) {
          let arr = t;
          // dispatch(tags([]));
          console.log(arr);
          console.log(videoRes.data.tags[0]);
          if (arr.indexOf(videoRes.data.tags[0]) === -1) {
            let count = arr.concat(videoRes.data.tags);
            dispatch(tags(count));
            console.log(count, " count");

            return checkRecord(videoRes.data.tags);
          } else {
            return console.log(
              "record not updated. Duplicate tag or something went wrong"
            );
          }
        }
      } catch (err) {
        return console.log(err);
      }
    };
    fetchData();
  }, [path, dispatch]);

  const updateRecord = async (tag) => {
    await axios.put(`${process.env.REACT_APP_API_URL}/records/${id}`, { tags: tag });
  };

  const checkRecord = async (tag) => {
    await axios.get(`${process.env.REACT_APP_API_URL}/records/${id}`).then((res) => {
      if (!res.data.tags.includes(tag)) {
        console.log(res.data.tags.includes(tag), "tag", tag);
        return updateRecord(tag);
      }
    });
  };

  // const updateUserTags = async (tag) => {
  //   await axios.put(`${process.env.REACT_APP_API_URL}/users/${currentUser._id}`, { tags: tag });
  // };

  // const checkUserTags = async (tag) => {
  //   await axios.get(`${process.env.REACT_APP_API_URL}/users/${currentUser._id}`).then((res) => {
  //     if (!res.data.tags.includes(tag)) {
  //       console.log(res.data.tags.includes(tag), "tag", tag);
  //       return updateUserTags(tag);
  //     }
  //   });
  // };

  const handleLike = async () => {
    if (currentUser) {
      await axios.put(`${process.env.REACT_APP_API_URL}/users/like/${currentVideo._id}`);
      dispatch(like(currentUser._id));
    }
  };
  const handleDislike = async () => {
    if (currentUser) {
      await axios.put(`${process.env.REACT_APP_API_URL}/users/dislike/${currentVideo._id}`);
      dispatch(dislike(currentUser._id));
    }
  };

  const handleSub = async () => {
    if (currentUser) {
      currentUser.subscribedUsers.includes(channel._id)
        ? await axios.put(`${process.env.REACT_APP_API_URL}/users/unsub/${channel._id}`)
        : await axios.put(`${process.env.REACT_APP_API_URL}/users/sub/${channel._id}`);
    } else {
      return;
    }
    dispatch(subscription(channel._id));
  };

  // tag tracking with current user
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const videoRes = await axios.get(`${process.env.REACT_APP_API_URL}/videos/find/${path}`);
  //       const channelRes = await axios.get(
  //         `${process.env.REACT_APP_API_URL}/users/find/${videoRes.data.userId}`
  //       );
  //       setVideo(videoRes.data);
  //       setChannel(channelRes.data);
  //       setChannel(channelRes.data);
  //       dispatch(fetchSuccess(videoRes.data));

  //       if (currentUser === null && id) {
  //         let arr = t;
  //         // dispatch(tags([]));
  //         if (arr.indexOf(videoRes.data.tags[0]) === -1) {
  //           let count = arr.concat(videoRes.data.tags);
  //           dispatch(tags(count));
  //           console.log(count, " count");
  //           return checkRecord(videoRes.data.tags);
  //         } else {
  //           return console.log("tags current");
  //         }
  //       } else if (currentUser) {
  //         let arr = t;
  //         // dispatch(tags([]));
  //         if (arr.indexOf(videoRes.data.tags[0]) === -1) {
  //           let count = arr.concat(videoRes.data.tags);
  //           dispatch(tags(count));
  //           console.log(count, " count");
  //           return checkUserTags(videoRes.data.tags);
  //         }
  //       }
  //     } catch (err) {
  //       return console.log(err);
  //     }
  //   };
  //   fetchData();
  // }, [path, dispatch]);

  //TODO: DELETE VIDEO FUNCTIONALITY

  return (
    <Container>
      <Content>
        <VideoWrapper>
          <iframe
            width="100%"
            height="520"
            src={video.videoUrl}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </VideoWrapper>
        <Title>{video.title}</Title>

        <Details>
          <Info>{video.views} views •</Info>
          <Info>{numberFormatter.format(video.views)} views •</Info>
          <Buttons>
            <Button onClick={handleLike}>
              {video.likes?.includes(currentUser?._id) ? (
                <ThumbUpIcon />
              ) : (
                <ThumbUpOutlinedIcon />
              )}{" "}
              {video.likes?.length}
            </Button>
            <Button onClick={handleDislike}>
              {video.dislikes?.includes(currentUser?._id) ? (
                <ThumbDownIcon />
              ) : (
                <ThumbDownOffAltOutlinedIcon />
              )}{" "}
              Dislike
            </Button>
            <Button>
              <ReplyOutlinedIcon /> Share
            </Button>
            <Button>
              <AddTaskOutlinedIcon /> Save
            </Button>
          </Buttons>
        </Details>
        <Hr />
        <Channel>
          <ChannelInfo>
            <Image src={video.channelImg} />
            <ChannelDetail>
              <ChannelName>{video.channelName}</ChannelName>
              <ChannelCounter>{channel.subscribers} subscribers</ChannelCounter>
              <Description>{video.desc}</Description>
            </ChannelDetail>
          </ChannelInfo>
          <Subscribe onClick={handleSub}>
            {currentUser && currentUser.subscribedUsers?.includes(channel._id)
              ? "SUBSCRIBED"
              : "SUBSCRIBE"}
          </Subscribe>
        </Channel>
        <Hr />
      </Content>
      <Recommendation tags={video.tags} />
    </Container>
  );
};

export default Video;
